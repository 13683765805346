import React, { useMemo } from 'react'
import { useAppSelector } from '../../redux/hooks'
import { selectModalStatus } from '../../redux/slices/modalSlice'
import { FlowStatus } from '../../constants/flow'
import {
  AdditionalVerification,
  AddToWallet,
  ConfirmPhoto,
  Error,
  NewStep,
  NumberForm,
  Request,
  SendData,
  SwitchToMobile,
  TakePhoto
} from './steps'

const flowStatusMap: Record<FlowStatus, () => React.JSX.Element> = {
  [FlowStatus.NEW]: NewStep,
  [FlowStatus.SWITCH_TO_MOBILE]: SwitchToMobile,
  [FlowStatus.NUMBER_FORM]: NumberForm,
  [FlowStatus.ADDITIONAL_VERIFICATION]: AdditionalVerification,
  [FlowStatus.TAKE_PHOTO]: TakePhoto,
  [FlowStatus.CONFIRM_PHOTO]: ConfirmPhoto,
  [FlowStatus.SEND_DATA]: SendData,
  [FlowStatus.ADD_TO_WALLET]: AddToWallet,
  [FlowStatus.ERROR]: Error,
  [FlowStatus.REQUEST]: Request
}

const Flow = () => {
  const status = useAppSelector(selectModalStatus)
  const FlowStep = useMemo(() => (status ? flowStatusMap[status] : null), [status])
  return FlowStep && <FlowStep key={Date.now()} />
}

export default Flow
