import { NewStepWrapper } from '../NewStep/styles'
import QRCode from 'react-qr-code'
import { QRCodeWrapper } from './styles'
import { useAppSelector } from '../../../../redux/hooks'
import { selectModalSubStatus } from '../../../../redux/slices/modalSlice'
import { useMemo } from 'react'

const getEnv = (tfEnv?: string) => {
  if (!tfEnv) return ''
  if (['dev', 'master'].includes(tfEnv)) return ''
  return `-${tfEnv}`
}

export const requestOnlineUrl = `https://gl-lnk.${process.env.REACT_APP_TF_VAR_domain}/i/NATIONAL_ID${getEnv(
  process.env.REACT_APP_TF_VAR_env
)}?action=request&data=eyJhY3Rpb24iIDogIlNVQk1JVCJ9`
export const requestOfflineUrl = `https://gl-lnk.${process.env.REACT_APP_TF_VAR_domain}/i/NATIONAL_ID${getEnv(
  process.env.REACT_APP_TF_VAR_env
)}?action=request&data=eyJhY3Rpb24iIDogIkdSQU5UIn0=`

const Request = () => {
  const subStatus = useAppSelector(selectModalSubStatus)

  const requestUrl = useMemo(() => {
    return subStatus === 'online' ? requestOnlineUrl : requestOfflineUrl
  }, [subStatus])

  const help = useMemo(() => {
    return subStatus === 'online' ? 'Request Online (w/o QR)' : 'Request Offline (w QR)'
  }, [subStatus])

  return (
    <NewStepWrapper background=''>
      <h2>Scan the QR code with your phone camera app</h2>
      <p>{help}</p>
      <QRCodeWrapper>
        <QRCode value={requestUrl} size={150} />
      </QRCodeWrapper>
    </NewStepWrapper>
  )
}

export default Request
